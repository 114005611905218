import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, withModifiers as _withModifiers } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_modal = _resolveComponent("a-modal")!

  return (_openBlock(), _createBlock(_component_a_modal, {
    visible: _ctx.visible,
    "onUpdate:visible": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.visible) = $event)),
    title: _ctx.title,
    footer: null
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_form, {
        labelCol: { span: 5 },
        wrapperCol: { span: 18 },
        onSubmit: _withModifiers(_ctx.save, ["prevent"]),
        model: _ctx.form
      }, {
        default: _withCtx(() => [
          _createVNode(_component_a_form_item, { label: "Sub Cluster" }, {
            default: _withCtx(() => [
              _createVNode(_component_a_select, {
                value: _ctx.form.sub_cluster_id,
                "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.sub_cluster_id) = $event)),
                id: "sub_cluster_id",
                required: "",
                "show-search": "",
                filterOption: _ctx.filterOption
              }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_sub_clusters.data, (item) => {
                    return (_openBlock(), _createBlock(_component_a_select_option, {
                      key: item.value
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(item.label), 1)
                      ]),
                      _: 2
                    }, 1024))
                  }), 128))
                ]),
                _: 1
              }, 8, ["value", "filterOption"])
            ]),
            _: 1
          }),
          _createVNode(_component_a_form_item, { "wrapper-col": { span: 18, offset: 5 } }, {
            default: _withCtx(() => [
              _createVNode(_component_a_space, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_button, {
                    key: "back",
                    onClick: _ctx.cancel
                  }, {
                    default: _withCtx(() => _cache[2] || (_cache[2] = [
                      _createTextVNode(" Cancel ")
                    ])),
                    _: 1
                  }, 8, ["onClick"]),
                  _createVNode(_component_a_button, {
                    key: "submit",
                    type: "primary",
                    htmlType: "submit",
                    loading: false
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode(" Submit ")
                    ])),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["onSubmit", "model"])
    ]),
    _: 1
  }, 8, ["visible", "title"]))
}