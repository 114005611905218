import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { style: {"margin-bottom":"8px","margin-top":"10px","text-align":"left","padding-left":"10px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_a_select_option = _resolveComponent("a-select-option")!
  const _component_a_select = _resolveComponent("a-select")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_col = _resolveComponent("a-col")!
  const _component_a_row = _resolveComponent("a-row")!
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_checkbox = _resolveComponent("a-checkbox")!
  const _component_sub_cluster = _resolveComponent("sub-cluster")!
  const _component_site_product = _resolveComponent("site-product")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_spin = _resolveComponent("a-spin")!
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    title: _ctx.$route.path.includes('create') ? 'Add Site' : 'Edit Site'
  }, {
    default: _withCtx(() => [
      _createVNode(_component_a_spin, { spinning: _ctx.isLoading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_a_form, {
              id: "myForm",
              labelCol: { span: 5 },
              wrapperCol: { span: 12 },
              model: _ctx.form,
              onFinish: _ctx.handleFinish
            }, {
              default: _withCtx(() => [
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Retailer User" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              "show-search": "",
                              filterOption: _ctx.filterOption,
                              value: _ctx.form.retailer_id,
                              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.retailer_id) = $event)),
                              id: "retailer_id",
                              required: "",
                              disabled: !_ctx.auth_user?.isAdmin(),
                              mode: "multiple"
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailers, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.id
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.full_name), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["filterOption", "value", "disabled"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Retailer Owner" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              "show-search": "",
                              filterOption: _ctx.filterOption,
                              value: _ctx.form.retailer_owner_id,
                              "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.retailer_owner_id) = $event)),
                              id: "retailer_owner_id",
                              required: ""
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_retailer_owners.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["filterOption", "value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Ship To" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              type: "text",
                              value: _ctx.form.ship_to,
                              "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.ship_to) = $event)),
                              id: "ship_to",
                              required: ""
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Site Name" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              type: "text",
                              value: _ctx.form.site_name,
                              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.site_name) = $event)),
                              id: "ship_name",
                              required: ""
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Type" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              type: "text",
                              value: _ctx.form.type,
                              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.form.type) = $event)),
                              id: "type"
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Arnoc" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_input, {
                              type: "text",
                              value: _ctx.form.arnoc,
                              "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.arnoc) = $event)),
                              id: "arnoc",
                              required: ""
                            }, null, 8, ["value"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "NHP Dealer" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_checkbox, {
                              value: _ctx.form.nhp_dealer,
                              checked: _ctx.checked_nhp,
                              onChange: _ctx.onChangeNhpDealer
                            }, null, 8, ["value", "checked", "onChange"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "NTI" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_checkbox, {
                              value: _ctx.form.nti,
                              checked: _ctx.checked_nti,
                              onChange: _ctx.onChangeNti
                            }, null, 8, ["value", "checked", "onChange"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[10] || (_cache[10] = _createElementVNode("h3", null, "Assignment", -1)),
                _cache[11] || (_cache[11] = _createElementVNode("hr", null, null, -1)),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Zone(Territory)" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.form.zone_id,
                              "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.zone_id) = $event)),
                              id: "zone_id",
                              onChange: _ctx.getZone,
                              required: "",
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_zones.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "onChange", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "RTM" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.territory_manager), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Full District" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.full_district), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "DM" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.district_manager), 1)
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _cache[12] || (_cache[12] = _createElementVNode("h3", null, "Site Mapping", -1)),
                _cache[13] || (_cache[13] = _createElementVNode("hr", null, null, -1)),
                _createVNode(_component_a_row, null, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_col, { span: 12 }, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_form_item, { label: "Default Cluster" }, {
                          default: _withCtx(() => [
                            _createVNode(_component_a_select, {
                              value: _ctx.form.cluster_id,
                              "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.cluster_id) = $event)),
                              id: "cluster_id",
                              required: "",
                              "show-search": "",
                              filterOption: _ctx.filterOption
                            }, {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.all_clusters.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_a_select_option, {
                                    key: item.value
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.label), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                }), 128))
                              ]),
                              _: 1
                            }, 8, ["value", "filterOption"])
                          ]),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }),
                _createVNode(_component_sub_cluster),
                _createVNode(_component_site_product),
                _createVNode(_component_a_row, { style: {"margin-top":"10px","float":"right"} }, {
                  default: _withCtx(() => [
                    _createVNode(_component_a_space, null, {
                      default: _withCtx(() => [
                        _createVNode(_component_a_button, {
                          onClick: _ctx.handleBack,
                          size: "large"
                        }, {
                          default: _withCtx(() => _cache[8] || (_cache[8] = [
                            _createTextVNode(" Back ")
                          ])),
                          _: 1
                        }, 8, ["onClick"]),
                        _createVNode(_component_a_button, {
                          type: "primary",
                          "html-type": "submit",
                          size: "large"
                        }, {
                          default: _withCtx(() => _cache[9] || (_cache[9] = [
                            _createTextVNode(" Submit ")
                          ])),
                          _: 1
                        })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["model", "onFinish"])
          ])
        ]),
        _: 1
      }, 8, ["spinning"])
    ]),
    _: 1
  }, 8, ["title"]))
}